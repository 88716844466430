<template>
    <div style="position:relative;display: flex;margin-left: 15px;margin-right: 15px">
        <!--        时间轴链接线-->
        <div style="border-left: 4px  rgba(80,227,194,0.3) dashed;border-spacing: 10px;height:100%;position: absolute;z-index: 1"></div>

        <div  >
            <!--      时间轴-->
            <div style="margin-bottom: 15px;display: flex;position: relative;">
                <div style="border-top: 4px  rgba(80,227,194,0.3)  dashed;border-spacing: 10px;width:100px;margin-top: 18px;margin-left: 6px"></div>
                <div style="border-radius: 4px;background: rgba(80,227,194,0.3);padding: 10px 20px; color: white;width:fit-content;display: flex;flex-wrap: wrap;align-items: center;justify-content: center">
                    <p style="margin-right: 20px">
                        {{ formatDate(item.createDate) }}
                    </p>
                    <!--      内容-->
                    <p v-if="item.content"   >
                        {{ item.content ? item.content : '又是元气满满的一天' }}
                    </p>
                </div>
            </div>
            <!--        图片-->
            <div v-if="item.imgList && item.imgList.length>0" style="margin-left: 25px;margin-bottom: 15px;display: flex"   >
                <el-image v-for="imageItem in item.imgList"  style="width: 100%;height:400px;object-fit: contain" :key="imageItem.imgUrl" :src="imageItem.imgUrl" ></el-image>
            </div>
        </div>

    </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    name: 'ui-farm-record-item',
    computed: {
        ...mapState({
            $token: state => state.$token,
            $farmInfo: state => state.$farmInfo,
            $farmDetailInfo: state => state.$farmDetailInfo,
            $farmUser: state => state.$farmUser,
        }),
    },
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            },
        },
        itemIndex: {
            type: Number,
            default: 0,
        },
    },
    watch: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        formatDate(date) {
            return date.substring(0, 10)
        },
        canDelete(uid) {
            if(this.$farmUser && uid ===  this.$farmUser.id ){
                return true;
            }
            if(this.$farmUser && this.$farmUser.type === 1){
                return true;
            }
            return false;
        },
        deleteRecord(recordId) {
            this.$confirm('确定要删除该记录吗？').then(() => {
                this.$http.delete('app/appfarmrecord/delete', {id: recordId}).then(() => {
                    this.$emit('refreshRecord');
                })
            }).catch(() => {
                this.$message('已取消删除');
            })
        },

    },
}
</script>

<style scoped lang="scss">
.imageBg {
    background: white;
    border: 5px solid #FFFFFF;
    transition: 1s;
    box-shadow: 5px 5px 5px #cccccc;
}

.pt11 {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

.pt12 {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
}

.pt21 {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

.pt22 {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
}

.pt31 {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}

.pt32 {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

.pt33 {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
}

.pt41 {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
}

.pt42 {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
}

.pt43 {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

.pt44 {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
</style>
