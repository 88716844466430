<template>
    <el-dialog :visible.sync="visible" :custom-class="topicDialogClass" :title="topicItem.name+'日志'"
               width="60%" :modal="false" style="z-index: 9999;">
        <div >
        <el-table
                border
                style="background: #03487B;width: 100%"
                height="550"
                :data="tableData"
                :header-cell-style="getHeadRowClass"
                :cell-style="getRowClass"
               >
            <el-table-column
                    header-align="center"
                    prop="createDate"
                    label="日期"
                    >
            </el-table-column>
            <el-table-column
                    header-align="center"
                    prop="name"
                    label="控制器名称"
                     >
            </el-table-column>
            <el-table-column
                    header-align="center"
                    prop="content"
                    label="操作消息">
            </el-table-column>

            <el-table-column
                    header-align="center"
                    prop="status"
                    label="状态">
                <template  v-slot="{ row }">

                    <div>
                    <div v-if="row.status===1" style="padding:8px 16px;font-size:20px;background: black;border: rgba(14, 246, 195, 0.7) 1px solid;color: rgba(14, 246, 195, 0.7);">
                        成功
                    </div>
                    <div  v-else  style="padding:8px 16px;font-size:20px;background: black;border: rgb(192,41,14) 1px solid;color: rgb(192,41,14);">
                        失败
                    </div>

                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    header-align="center"
                    label="操作">
                <template slot-scope="scope">
                    <el-button size="medium" icon="el-icon-delete" style="font-size:20px;background: #F56C6C;border: rgb(255,255,255) 1px solid;color: rgb(255,255,255);font-weight: bold" @click="deleteLog(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                style="margin-top: 20px"
                background
                layout="prev, pager, next"
                :page-size="pageSize"
                :current-page.sync="page"
                :total="total"
                @current-change="onCurrentChange"
                @size-change="onSizeChange"
                @prev-click="onCurrentChange(--page)"
                @next-click="onCurrentChange(++page)"
        >
        </el-pagination>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: 'ui-topic-log',
    data() {
        return {
            visible: false,
            tableData: [],
            topicItem: {},
            topicDialogClass: 'custom-dialog-background',
            page: 1,
            pageSize: 10,
            total: 0,
        };
    },
    methods: {
        getRowClass({row, column, rowIndex, columnIndex}) {
            return `background:#03487B;color:white;font-size: 18px`
        },
        getHeadRowClass({row, column, rowIndex, columnIndex}) {
            return 'background:rgb(11, 32, 51);color:white;'
        },
        open(topicItem) {
            this.topicItem = topicItem
            this.visible = true
            this.page = 1
            this.loadData()
        },
        loadData() {
            const params = {
                page: this.page,
                limit: this.pageSize,
                topic_id: this.topicItem.id,
                orderField: 'create_date',
                order: 'desc',
            }
            this.$http.get('app/appfarmtopiclog/list', {
                params: params,
            }).then(res => {
                this.tableData = res.data.data.list
                this.total = res.data.data.total
            })
        },
        deleteLog(id) {
            this.$http.delete('app/appfarmtopiclog/delete', {
                data: {
                    id: id
                }
            }).then(res => {
                this.$message.success('删除成功')
                this.loadData()
            })
        },
        onCurrentChange(page) {
            this.page = page
            this.loadData()
        },
        onSizeChange(size) {
            this.pageSize = size
            this.loadData()
        },
    },

}
</script>


<style lang="scss">
.el-table thead{
    font-size: 18px;
}
.el-table td.el-table__cel{
    background: blue;
}

//去掉表格/*头部边框*/
::v-deep .el-table thead tr th.is-leaf{
    border: 0px solid #EBEEF5;
    border-right: none;
}
::v-deep .el-table {
    // 去掉table的border start----------------------------
    border: 0;
    th,tr,td{ border: 0; }
    &::before { height: 0px;}
    &::after {  width: 0;}
    .el-table__fixed:before { height: 0; }
    // 去掉table的border end----------------------------
}
//整个table的背景颜色
::v-deep .el-table, .el-table__expanded-cell {
    background-color: transparent;
    color: white;
}
//表格整行的颜色
::v-deep .el-table tr {
    background-color: transparent!important;
}
::v-deep .el-table tbody tr:hover>td {
    background-color: rgba($color: #03487B, $alpha:0.5) !important;
}
::v-deep .el-table–enable-row-transition .el-table__body td, .el-table .cell{
    background-color: transparent;
}
</style>
