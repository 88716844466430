<template>
    <div>
        <div  id="index" class="bg">
            <ui-farm-chart ref="farmChart" :current-item="currentItem"></ui-farm-chart>
            <ui-topic-controller ref="topicController" @refreshDataList="loadTopicList"></ui-topic-controller>
            <ui-topic-log ref="topicLog"></ui-topic-log>
            <ui-farm-land-edit ref="landEdit" />
            <dv-loading v-if="loading"  >
                <div class="loading-text">加载中...</div>
            </dv-loading>
            <div v-else  >
                <!--      头部  start-->
                <div  style="height: 15vh" >
                    <div style="display: flex;justify-content: center;flex-direction: row">
                        <dv-decoration-10 style="width:100%;height: 10px"/>
                        <div style="display: flex;flex-direction: column;justify-content: center;text-align: center;margin-left: 10px;margin-right: 10px">
                            <div style="font-size: 40px;color: white;font-weight: bold;margin-bottom: 20px;white-space: nowrap">
                                {{ $farmInfo.farmName }}
                            </div>
                            <dv-decoration-5 style="width:100%;height:40px;"/>
                        </div>
                        <dv-decoration-10 style="height:10px;transform: rotateY(180deg)"/>
                    </div>
                    <div style="display: flex;margin-top: -60px;justify-content: space-between;height: 12vh;">
                        <dv-border-box-13 style="flex:1;align-items: center;">
                            <div style="margin-top: 30px;margin-left: 20px;font-size: 16px;color: #4ECFDB">
                                <div style="margin-right: 5px;font-size: 1.2em">
                                    {{ gaodeWeather.city }}气象实时数据:
                                </div>
                                <div
                                        style="display: flex;flex-direction:row;align-items: center;margin-top: 10px">
                                    <span :class="['t-icon','t-icon-daqishidu_mian']"></span>
                                    <div style="margin-left: 5px;margin-right: 15px;">
                                        {{ '湿度 ' + gaodeWeather.humidity + ' ℃ ' }}
                                    </div>
                                    <span :class="['t-icon','t-icon-daqiwendu_mian']"></span>
                                    <div style="margin-left: 5px;margin-right: 15px;">
                                        {{ '温度 ' + gaodeWeather.temperature + ' ℃ ' }}
                                    </div>
                                    <span style="margin-right: 5px">天气</span>
                                    <span :class="['t-icon',weatherIcon]"></span>
                                    <div style="margin-left: 5px;margin-right: 15px;">
                                        {{ gaodeWeather.weather }}
                                    </div>
                                    <span style="margin-right: 5px">风向</span>
                                    <span class="t-icon t-icon-tianqidafeng"></span>
                                    <div style="margin-left: 5px;margin-right: 15px">
                                        {{ gaodeWeather.windPower + ' 级  ' + gaodeWeather.windDirection + '风' }}
                                    </div>
                                </div>
                            </div>
                        </dv-border-box-13>
                        <div style="width: 20vw;position: relative">
                            <dv-decoration-6 style="height:20px;margin-top: 70px" :color="['#50e3c2', '#67a1e5']">
                            </dv-decoration-6>
                        </div>
                        <dv-border-box-13 style="flex:1;transform: rotateY(180deg);display: flex;position: relative">
                            <div style="margin-top: 30px;transform: rotateY(-180deg);display: flex;flex-direction: column;padding-left: 20px;font-size:18px;font-weight: bold;color: white;">
                                <div style="padding-left: 30px;font-size: 16px;font-weight: bold">
                                    <span class="text" style="color: #4ECFDB;font-size: 1.2em">{{ dateYear }} {{
                                            dateWeek
                                        }} {{ dateDay }}</span><span
                                        style="float: right;margin-right: 20px;;display: flex;align-items: center">农场账号:{{
                                        $farmUser.username
                                    }}

                                <span style="margin-left:15px;color: #4ECFDB;border: #4ECFDB 1px solid;padding: 3px 10px;background: rgba(78,207,219,0.2);border-radius: 8px"
                                      @click="logout">切换账号</span>  </span>

                                </div>
                                <dv-decoration-3 style="width:90%;height:20px;margin: 8px"/>
                            </div>
                        </dv-border-box-13>
                    </div>
                </div>
                <!--                头部  end-->
                <div style="display: flex;justify-content: space-between;margin-top: 10px;height: 85vh"    >
                    <div  style="flex: 0.42;display: flex;flex-direction: column;"    >
                    <div class="land-list-view">
                        <div style="text-align: center;display: flex;flex-direction: row;justify-content: center;align-items: center;margin: 15px 0;height: 50px">
                            <dv-decoration-6 style="width:100px;height:10px;"
                                             :color="['#50e3c2', '#67a1e5']"/>
                            <div style="font-size: 20px;color: white;margin-left: 10px;margin-right: 10px">农田数据
                            </div>
                            <dv-decoration-6 style="width:100px;height:10px;"
                                             :color="['#50e3c2', '#67a1e5']"/>
                        </div>
                        <div :style="{height:'65vh'}" style="overflow-y: scroll;">
                            <ui-farm-land-item v-for="(item,index) in landList" :key="index" :checked="item.checked"
                                               :index="index" :item="item" @onSelect="onSelect"  @onEdit="onEdit"/>
                            <dv-border-box-8
                                             :backgroundColor="thirdColors[2]"
                                             :color="thirdColors"
                                             style="height: 60px;line-height: 60px;
                                             margin:20px;width: calc(100% - 40px);
                                             text-align:center;cursor: pointer"

                            >
                                    <span style="text-align: center;color: white;width: calc(100% - 40px);height: 60px"  @click="onEdit({})">  新增农田 ✓</span>
                            </dv-border-box-8>
                        </div>
                    </div>
                    </div>
                    <div style="flex: 1;margin-left: 15px;width: 100%;display: flex;">
                        <dv-loading v-if="rightLoading">
                            <div class="loading-text">加载中...</div>
                        </dv-loading>
                        <div v-else  class="land-list-view"
                                          :style="{height: '75vh',borderColor:activeName==='second'?secondColors[0]:firstColors[0]}"
                                          style="padding:20px;font-size: 24px;width: 100%;">
                            <el-tabs stretch v-model="activeName"
                                     style="width: 100%;height: 100%"
                                     ref="tabsRef"
                                     type="card"
                                     @tab-click="handleClick">


                                     <div v-if=" activeName === 'second' || activeName ==='first'" style="position: relative;height: 8vh" >
                                    <p style="font-size: 16px;line-height: 28px;margin-top: 10px;margin-left: 10px;margin-right: 10px;min-height: 60px"
                                    >{{ '知识小贴士:'+currentItem.remark }}</p>


                                         <div
                                                 style="cursor:pointer;font-size:14px;text-align: center;color: white;position: absolute;right: 20px;bottom: 0;display: flex;justify-content: flex-end"
                                               >
                                         <el-select    v-if=" activeName === 'second'"
                                                       v-model="currentLandItem.soilConfig"
                                                       class="right-select" placeholder="选择传感器"  style="width: 170px;margin-right: 10px" @change="onSelectSoil">
                                             <el-option
                                                     v-for="item in soilList"
                                                     :key="item.value"
                                                     :label="item.label"
                                                     :value="item.value">
                                             </el-option>
                                         </el-select>
                                             <div
                                                     class="land-list-view"
                                                     :style="{borderColor:activeName==='second'?secondColors[0]:firstColors[0]}"
                                                     style="cursor:pointer;border-radius:5px;background:rgba(80,227,194,0.7);padding:5px 10px;font-size:14px;text-align: center;color: white;" @click="showCharts"> {{
                                                     currentItem.name
                                                 }} 24小时趋势图 ✓</div>
                                         </div>
                                     </div>
                                <el-tab-pane label="知天" name="first">
                                    <div style="margin-top: 10px;overflow-y: scroll" :style="{height:'48vh'}">
                                    <el-row >
                                        <el-col :span="6" v-for="(item, index) in firstList" :key="index">
                                            <div
                                                    @click="onFirstChick(index)"
                                                    :style="{marginRight:  '10px' }"
                                                    style="cursor:pointer;margin-bottom: 10px">
                                              <div :style="{background:item.checked?firstColors[1] :firstColors[2]}" style="display: flex;align-items: center;border:rgba(80,227,194,0.7) 2px solid;border-radius: 8px;padding: 20px 16px">
                                              <span :class="[`t-icon`,item.icon]" :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)',fontSize:pageStyle.iconSize+'px' }"></span>
                                                <div :style="{marginLeft: '12px',fontSize:pageStyle.fontSize+'px'}">
                                                    <div style="font-weight: 500;margin-bottom: 18px;"
                                                         :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)' }">
                                                        {{
                                                            item.name
                                                        }}
                                                    </div>
                                                    <div :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)' }"
                                                         style="font-weight: 500;white-space: nowrap;">
                                                      {{ `${item.data ? item.data : '0'} ${item.unit}` }}
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                                </el-tab-pane>
                                <el-tab-pane label="懂地" name="second">
                                    <div style="margin-top: 10px;overflow-y: scroll"   :style="{height:'52vh'}">
                                    <el-row >
                                        <el-col :span="6" v-for="(item, index) in secondList" :key="index">
                                            <div
                                                    @click="onSecondChick(index)"
                                                    :style="{marginRight:  '10px' }"
                                                    style="cursor:pointer;margin-bottom: 10px">
                                                <div :style="{background:item.checked?secondColors[1] :secondColors[2]}" style="display: flex;align-items: center;border:rgba(80,227,194,0.7) 2px solid;border-radius: 8px;padding: 20px 16px">
                                               <span :class="[`t-icon`,item.icon]" style="font-size:60px;"
                                                     :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)' }"></span>
                                                    <div :style="{marginLeft: '12px',fontSize:pageStyle.fontSize+'px'}">
                                                    <div style="font-weight: 500;margin-bottom: 18px;"
                                                         :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)' }">
                                                        {{
                                                            item.name
                                                        }}
                                                    </div>
                                                    <div :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)' }"
                                                         style="font-weight: 500;white-space: nowrap;">
                                                        {{ `${item.data ? item.data : '0'} ${item.unit}` }}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="驭水" name="third">
                                    <el-row  style="margin-top: 10px;overflow-y: scroll"  :style="{height:'60vh'}">
                                        <el-col :span="8" v-for="(topicItem,topicIndex) in topicList" :key="topicIndex">
                                            <div style="height:120px;border-radius: 10px;position: relative;display: flex;flex-direction: column;margin-bottom:8px"
                                                 :style="{marginRight:  '10px',background:topicItem.checked?thirdColors[0]:thirdColors[2] ,border:thirdColors[1]+' 2px solid'}"
                                            >
                                                <div :style="{background:thirdColors[0]}"
                                                     style="border-radius: 8px 8px 0 0;padding: 0 10px;font-size: 18px;align-items: center">
                                                    <span v-if="topicItem.type===1" class="el-icon-time"/>
                                                    <span style="height: 50px;line-height: 50px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;"> {{
                                                            topicItem.name + ''
                                                        }}</span>
                                                </div>

                                                <div style="display: flex;justify-content: space-around;padding: 5px;align-items: center;height:100%">
                                                    <el-button-group>
                                                        <el-button
                                                                @click="changeTopicSwitch(topicItem,true)"
                                                                :style="{background:topicItem.status === 1? thirdColors[1]:'#cccccc',color:topicItem.status === 1? '#ffffff':'#000000'}">
                                                            开启
                                                        </el-button>
                                                        <el-button
                                                                @click="changeTopicSwitch(topicItem,false)"
                                                                :style="{background:topicItem.status === 0? thirdColors[1]:'#cccccc',color:topicItem.status === 0? '#ffffff':'#000000'}">
                                                            关闭
                                                        </el-button>
                                                    </el-button-group>
                                                    <div style="font-size: 16px;line-height: 22px;display: flex;align-items: center;cursor: pointer"
                                                         @click="onOpenTopicLog(topicItem)">
                                                        <span class="t-icon t-icon-rizhi" style="font-size: 22px;"/>查看日志
                                                    </div>

                                                    <div style="font-size: 16px;line-height: 22px;display: flex;align-items: center;cursor: pointer;"
                                                         @click="onOpenTopic(topicItem)">
                                                        <span class="t-icon t-icon-zhilingpeizhi"
                                                              style="position: absolute;right:0;padding: 10px;font-weight: normal"></span>
                                                        设置
                                                    </div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div style="height:120px;border-radius: 10px;border:#2DB26F  1px solid;position: relative;display: flex;
                                        flex-direction: column;margin-bottom:8px"
                                                 :style="{marginRight:  '10px',background:thirdColors[0] ,border:thirdColors[1]+' 2px solid'}"
                                                 @click="onOpenTopic({})"
                                            >
                                                <div style="display: flex;align-items: center;justify-content: center;line-height: 120px">
                                                    <span class="el-icon-plus"/>添加控制器
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="识情" name="fourth">
                                    <ui-live-video ref="liveVideo" :height="videoHeight"></ui-live-video>
                                </el-tab-pane>
                                <el-tab-pane label="溯源" name="fifth">
                                    <el-row :style="{height: '60vh'}"
                                            style="margin-top: 10px;overflow-y: scroll">
                                        <el-col :span="16" v-for="(recordItem,index) in recordList" :key="index">
                                            <ui-farm-record-item :item="recordItem" :itemIndex="index"/>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>

                            </el-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import drawMixin from '@/utils/drawMixin';
import {mapState} from 'vuex';
import AMapLoader from '@vuemap/amap-jsapi-loader';
import UiFarmLandItem from '_c/ui-farm-land-item.vue';
import UiFarmRecordItem from '_c/ui-farm-record-item.vue';
import {formatTime} from '@/utils/index.js'
import UiLiveVideo from '_c/ui-live-video.vue';
import UiFarmChart from '_c/ui-farm-chart.vue';
import UiTopicController from '_c/ui-topic-controller.vue';
import UiTopicLog from '_c/ui-topic-log.vue';
import UiFarmLandEdit from '_c/ui-farm-land-edit.vue';

export default {
    mixins: [drawMixin],
    components: {
        UiFarmLandEdit,
        UiTopicLog, UiTopicController, UiFarmChart, UiLiveVideo, UiFarmRecordItem, UiFarmLandItem},
    computed: {
        ...mapState({
            $token: state => state.$token,
            $farmUser: state => state.$farmUser,
            $farmInfo: state => state.$farmInfo,
            $farmDetailInfo: state => state.$farmDetailInfo,
        }),
        bodyHeight() {
            return Number((this.screenHeight) * 0.74)
        },
        leftBodyHeight(){
            return (this.bodyHeight - 250) + 'px'
        },
        leftLandHeight(){
            return (this.bodyHeight - 100) + 'px'
        },
        rightBodyHeight() {
            return (this.bodyHeight - 10) + 'px'
        },
        rightDataHeight() {
            return (this.bodyHeight - 130 -130) + 'px'
        },
    },
    data() {
        return {
            videoHeight:0,
            soilList: [],
            screenHeight: window.innerHeight,
            headerHeight: 0,
            fullHeight:0,
            timing: null,
            loading: true,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            firstColors: ['#07b08a', 'rgba(14,246,195,0.7)', 'rgba(80,227,194,0.3)'],
            secondColors: ['#43c065', 'rgb(64,222,107)', 'rgba(80,227,194,0.3)'],
            thirdColors: ['rgba(71,118,183,0.7)', '#5592ea', 'rgba(59,109,180,0.2)'],
            activeName: 'first',
            gaodeWeather: {
                city: '',
                temperature: '0',
                humidity: '0',
                weather: '晴',
                windPower: '3',
                windDirection: '东北',
            },
            weatherIcon: 't-icon-tianqiqingzhuanduoyun',
            tabList: [
                {
                    name: 'first',
                    label: '知天',
                    checked: true,
                },
                {
                    name: 'second',
                    label: '懂地',
                    checked: false,
                },
                {
                    name: 'third',
                    label: '驭水',
                    checked: false,
                },
                {
                    name: 'fourth',
                    label: '识情',
                    checked: false,
                },
                {
                    name: 'fifth',
                    label: '溯源',
                    checked: false,

                },
            ],
            tabCurrent: 0,
            //知天
            firstList: [
                {
                    name: '光照强度',
                    icon: 't-icon-rizhaoshishu_mian',
                    unit: 'lux',
                    key: 'lux',
                },
                {
                    name: '二氧化碳',
                    icon: 't-icon-eryanghuatan_mian',
                    unit: 'ppm',
                    key: 'co2',
                },
                {
                    name: '温度',
                    icon: 't-icon-daqiwendu_mian',
                    unit: '℃',
                    key: 'wendu',
                },
                {
                    name: '湿度',
                    icon: 't-icon-daqishidu_mian',
                    unit: '%',
                    key: 'shidu',
                },
                {
                    name: '雨量',
                    icon: 't-icon-jiangyuliang_mian',
                    unit: 'mm',
                    key: 'yuliang',
                },
                {
                    name: 'PM2.5',
                    icon: 't-icon-a-PM25_mian',
                    unit: 'ug/m3',
                    key: 'pm25',
                },
                {
                    name: 'PM10',
                    icon: 't-icon-PM10_mian',
                    unit: 'ug/m3',
                    key: 'pm10',
                },
                {
                    name: '气压',
                    icon: 't-icon-qiya_mian',
                    unit: 'kpa',
                    key: 'kpa',
                },
                {
                    name: '悬浮颗粒',
                    icon: 't-icon-TSPxuanfukeli-xian',
                    unit: 'ug/m3',
                    key: 'tps',
                },
                {
                    name: '风速',
                    icon: 't-icon-fengsu',
                    unit: 'm/s',
                    key: 'fengshu',
                },
                {
                    name: '风向',
                    icon: 't-icon-fengxiang',
                    unit: '',
                    key: 'fengxiang',
                },
                {
                    name: '雨雪',
                    icon: 't-icon-bingdongyuxue',
                    unit: 'mm',
                    key: 'yuxue',
                },
            ],
            //懂地
            secondList: [
                {
                    name: '土壤温度',
                    icon: 't-icon-turangwendu',
                    data: [],
                    unit: '℃',
                    key: 'tuwen',
                },
                {
                    name: '土壤湿度',
                    icon: 't-icon-turangshidu',
                    data: [],
                    unit: '%',
                    key: 'tushi',
                },
                {
                    name: '土壤酸碱',
                    icon: 't-icon-turangpH',
                    data: [],
                    unit: 'ph',
                    key: 'ph',
                },
                {
                    name: '电导率',
                    icon: 't-icon-turangdiandaoshuai',
                    data: [],
                    unit: 'us/cm',
                    key: 'ec',
                },
                {
                    name: '氮',
                    icon: 't-icon-turangdanhanliang',
                    data: [],
                    unit: 'mg/kg',
                    key: 'n',
                },
                {
                    name: '磷',
                    icon: 't-icon-turanglinhanliang',
                    data: [],
                    unit: 'mg/kg',
                    key: 'p',
                },
                {
                    name: '钾',
                    icon: 't-icon-turangjiahanliang',
                    data: [],
                    unit: 'mg/kg',
                    key: 'k',
                },
            ],
            windDirections: [
                {key: 0, value: '北风'},
                {key: 1, value: '东北风'},
                {key: 2, value: '东风'},
                {key: 3, value: '东南风'},
                {key: 4, value: '南风'},
                {key: 5, value: '西南风'},
                {key: 6, value: '西风'},
                {key: 7, value: '西北风'},
            ],
            landList: [],
            currentItem: {},
            dictList: [],
            rightLoading: true,
            currentLandItem: {
                soilTopic: '',
            },
            topicList: [],
            topicStatusList: [],
            recordList: [],
            pageStyle:{
                iconSize:0,
                fontSize:0,
            }
        }
    },
    mounted() {
        if (!this.$token) {
            this.$router.push({name: 'login'});
        } else {
            this.loadData();
        }
        this.timeFn()
        this.updateScreenHeight()
        window.addEventListener('resize', this.updateScreenHeight);
    },
    beforeDestroy() {
        clearInterval(this.timing)
        window.removeEventListener('resize', this.updateScreenHeight);
    },
    methods: {
        onSelectSoil(value) {
            this.currentLandItem.soilConfig = value
           this.$http.post('app/appfarmland/save', {
                id: this.currentLandItem.id,
                soilConfig: value,
                farmUserId: this.$farmInfo.farmUId,
            }).then(res => {
                this.$message({  type: 'success',message:'土壤传感器设置成功'})
                this.loadWeatherList(1, value)
            }).catch(() => {})
        },
        toShowSoil() {
            if (this.soilList == null || this.soilList.length === 0) {
                this.$http.get(`app/iot/soil/topicList?topic=${this.currentLandItem.soilTopic?this.currentLandItem.soilTopic:this.currentLandItem.weatherTopic}`).then(res => {
                    if (res) {
                        this.soilList = res.data.data.map((item) => {
                            return {
                                label: item.name,
                                value: item.key,
                            }
                        })
                    }
                }).catch(errors => {
                    console.log(errors)
                })
            }
        },
        updateScreenHeight() {
            this.pageStyle.fontSize = Number(window.innerWidth / 19.2 * 0.26);
            this.pageStyle.iconSize = Number(window.innerWidth/ 19.2 * 0.8);
            this.videoHeight = Number( window.innerHeight * 0.6)
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        handleClick(tab) {
            if (tab.name === 'first') {
                this.onFirstChick(0)
                this.$forceUpdate()
            }
            if (tab.name === 'second') {
                this.onSecondChick(0)
                this.$forceUpdate()
            }
            if (tab.name === 'fourth') {
                if (this.$refs.liveVideo) {
                    this.$refs.liveVideo.play(this.currentLandItem.cameraConfig)
                }
            } else {
                if (this.$refs.liveVideo) {
                    this.$refs.liveVideo.stop()
                }
            }

        },
        onEdit(landItem) {
            this.$refs.landEdit.open(landItem)
        },
        onSelect(index) {
            this.landList.forEach(item => {
                item.checked = false;
            });
            this.landList[index].checked = true;
            this.currentLandItem = this.landList[index];
            this.loadWeatherList(0,this.currentLandItem.soilConfig)
            this.loadWeatherList(1,this.currentLandItem.soilConfig)
            this.soilList =[]
            this.toShowSoil()
            this.loadTopicList()
            this.loadRecordList()
            this.$nextTick(() => {
                if (this.$refs.liveVideo) {
                    this.$refs.liveVideo.play(this.currentLandItem.cameraConfig)
                }
            })
            this.$forceUpdate()
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        loadRecordList(page = 1, pageSize = 10) {
            const params = {
                page: page,
                limit: pageSize,
                fid: this.currentLandItem.fid,
                flid: this.currentLandItem.id,
                orderField: 'create_date',
                order: 'desc',
            }
            this.$http.get('app/appfarmrecord/list', {
                params: params,
            }).then(res => {
                this.recordList = res.data.data.list
            }).catch(() => {
                this.recordList = []
            })
        },
        //获取高德天气
        loadGaoWeather() {

            this.$http.get('app/iot/ip/city', {}).then(res => {
                let that = this;
                AMapLoader.load({
                    key: '6230d00bf3fe2ffa4ca2ae8897797e6a', // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ['AMap.CitySearch', 'AMap.Geocoder', 'AMap.Weather'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                })
                        .then((AMap) => {
                            that.getWeather(AMap, res.data.data);
                        })
                        .catch((e) => {
                            console.log(e);
                        });

            });

        },
        // 获取天气
        getWeather(AMap, city) {
            let that = this;
            //加载天气查询插件
            AMap.plugin('AMap.Weather', function () {
                //创建天气查询实例
                let weather = new AMap.Weather();
                //执行实时天气信息查询
                weather.getLive(city, function (err, data) {
                    if (data.info === 'OK') {
                        that.gaodeWeather = data;
                        that.weatherIcon = that.parseWeatherIcon(that.gaodeWeather.weather);
                    }
                });
            });
        },
        parseWeatherIcon(weather) {
            if (weather) {
                if (weather.includes('小雨')) {
                    return 't-icon-tianqiyutianxiaoyu'
                } else if (weather.includes('大雨')) {
                    return 't-icon-tianqiyutiandayu'
                } else if (weather.includes('阴')) {
                    return 't-icon-tianqiyintian'
                } else {
                    return 't-icon-tianqiqingzhuanduoyun'
                }
            }
            return 't-icon-tianqiqingzhuanduoyun'
        },
        loadData() {

            this.$http.get('app/appfarm/info', {
                params: {
                    farmId: this.$farmInfo.farmId,
                    farmUserId: this.$farmInfo.farmUId,
                },
            }).then(res => {
                this.$store.commit('setFarmDetailInfo', res.data.data);
                this.loading = true;
                this.loadGaoWeather();
                this.loadLandList();
                this.cancelLoading();
            });
        },
        loadDictList() {
            return new Promise((resolve, reject) => {
                if (this.dictList.length > 0) {
                    resolve()
                } else {
                    this.$http.get('app/iot/weather/dict', {}).then(res => {
                        this.dictList = res.data.data
                        resolve()
                    }).catch(err => {
                        reject()
                    })
                }
              });
        },
        loadWeatherList(type,soilConfig) {
            this.rightLoading = true
            this.loadDictList().then(() => {
                let topic  = type === 1?  this.currentLandItem.soilTopic ?this.currentLandItem.soilTopic:this.currentLandItem.weatherTopic:this.currentLandItem.weatherTopic;
                this.$http.get(`app/iot/farm/weather/current?topic=${topic}&isAll=true&soilConfig=${soilConfig}`, {}).then(result => {
                    let res = result.data.data;
                    this.rightLoading = false
                    Object.keys(res).forEach(key => {
                        if(type===0) {
                            this.firstList.forEach(item => {
                                if (item.key === key) {
                                    if (key === 'fengxiang') {
                                        item.data = this.windDirections[Number(res[key])].value
                                    } else {
                                        item.data = res[key]
                                    }
                                }
                            })
                        }
                        if(type===1) {
                            //  土壤传感器数据
                            this.secondList.forEach(item => {
                                if (item.key === key) {
                                    item.data = res[key]
                                }
                            })
                        }
                    })
                    if(type===0) {
                        this.firstList.forEach(item => {
                            item.checked = false
                            this.dictList.forEach(dict => {
                                if (dict.dictValue === item.name) {
                                    item.remark = dict.remark
                                    item.unit = dict.dictLabel
                                }
                            })
                        })
                        this.onFirstChick(0)
                    }
                    if(type===1) {
                        this.secondList.forEach(item => {
                            item.checked = false
                            this.dictList.forEach(dict => {
                                if (dict.dictValue === item.name) {
                                    item.remark = dict.remark
                                    item.unit = dict.dictLabel
                                }
                            })
                        })
                    }


                })
            })
        },
        onFirstChick(index) {
            this.firstList.forEach(item => {
                item.checked = false
            })
            this.firstList[index].checked = true
            this.currentItem = this.firstList[index]
            this.currentItem.weatherTopic = this.$farmDetailInfo.weatherTopic
            this.$forceUpdate()
        },
        onSecondChick(index) {
            this.secondList.forEach(item => {
                item.checked = false
            })
            this.secondList[index].checked = true
            this.currentItem = this.secondList[index]
            this.currentItem.weatherTopic = this.$farmDetailInfo.weatherTopic
            this.$forceUpdate()
        },
        loadLandList() {
            const params = {
                page: 1,
                limit: 999,
                fid: this.$farmInfo.farmId,
            }
            this.$http.get('app/appfarmland/list', {
                params: params,
            }).then(res => {
                this.landList = res.data.data.list;
                // this.loadWeatherList(1,this.landList[0].soilConfig)
                this.onSelect(0)
            })
        },
        logout() {
            this.$store.commit('clearToken');
            this.$router.push({name: 'login'});
        },
        loadTopicList() {

            this.$http.get('app/appfarmtopic/list', {
                params: {
                    page: 1,
                    limit: 999,
                    fuid: this.$farmUser.id,
                    fid: this.$farmDetailInfo.id,
                    flid: this.currentLandItem.id,
                    orderField: 'sort',
                    order: 'asc',
                },
            }).then(res => {
                this.topicList = res.data.data.list

                this.loadStatus()
            })
        },
        loadStatus() {
            this.$http.get('app/iot/watering/status', {
                params: {
                    topic: this.currentLandItem.wateringTopic,
                },
            }).then(res2 => {
                this.topicStatusList = res2.data.data
                this.topicList.forEach(item => {
                    if (item.type === 0) {
                        this.topicStatusList.forEach(item2 => {
                            if (item.msgOn === item2.on) {
                                item.status = item2.state
                            }
                        })
                    }
                })

            })
        },
        showCharts() {
            this.$nextTick(() => {
                this.$refs.farmChart.open(this.currentItem)
            })
        },
        onOpenTopic(topicItem) {
            this.$nextTick(() => {
                this.$refs.topicController.open(this.currentLandItem, topicItem)
            })
        },
        changeTopicSwitch(topic, isSwitch) {
            this.$msgbox.confirm('确定要' + (isSwitch ? '开启' : '关闭') + '指令吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                topic.isOn = isSwitch
                this.$http.post('app/appfarmtopic/send', topic).then(res => {
                    this.$message({
                        type: 'success',
                        message: '发送成功',
                    });
                    this.topicList.forEach((item) => {
                        if (item.id === topic.id) {
                            item.status = isSwitch ? 1 : 0
                        }
                    })
                }).catch(err => {
                    this.$message({
                        type: err + '\n发送失败,请检查指令是否正确？',
                        message: '发送失败',
                    });
                })
            }).catch(() => {
            });
        },
        onOpenTopicLog(topicItem) {
            this.$nextTick(() => {
                this.$refs.topicLog.open(topicItem)
            })

        },

    },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.loading-text {
    margin-top: 10px;
    color: #4ECFDB;
}

::v-deep .el-tabs__item {
    color: rgba(255, 255, 255, 0.4);
    font-size: 30px;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.3);
    height: 60px;
    line-height: 60px;
}

::v-deep .el-tabs__item.is-active {
    border-radius: unset;
    color: white;
    background: #50e3c2;
}

//
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child.is-active {
    background: #50e3c2;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2).is-active {
    background: #43c065;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(3).is-active {
    background: #3b6db4;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(4).is-active {
    background: #3ca158;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(5).is-active {
    background: rgb(59, 140, 121);
}

//
//::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
//    background: rgba(80, 227, 194, 0.3);
//}
//
//::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
//    background: rgba(204, 112, 64, 0.3);
//}
//
//::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(3) {
//    background: rgba(59, 109, 180, 0.3);
//}
//
//::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(4) {
//    background: rgba(94, 204, 125, 0.3);
//}

::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.land-list-view{
    // 描边 遮罩 阴影 效果
    border: #1a3456 3px solid;
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.2) 3px 5px 5px;
}
.el-select-dropdown__item {
    color: #50e3c2;
    font-size: 16px;
}
.right-select {
    // 修改input默认值颜色 兼容其它主流浏览器
    ::v-deep input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.50);
    }
    ::v-deep input::-moz-input-placeholder {
        color: rgba(255, 255, 255, 0.50);
    }
    ::v-deep input::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.50);
    }
    // input框
    ::v-deep .el-select,
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
        background-color: rgba(255, 255, 255, 0.04);
        color: white;
        border-color: #50e3c2;
        text-align: left;
        border-radius: 4px;
        font-size: 16px;
        height: 36px;
    }
    ::v-deep .el-select.el-select-dropdown.el-select-dropdown__item {
        color: #50e3c2;
        font-size: 16px;
    }

    // 选中时边框颜色
    // ::v-deep .el-input__inner:focus{
    //     border-color: #XXXXXX;
    // }

    // 鼠标悬浮时 input框颜色
    ::v-deep .el-input__inner:hover{
        background-color: rgba(255, 255, 255, 0.12);
    }

    // input框 右侧的箭头
    ::v-deep .el-select .el-input .el-select__caret {
        color: rgba(255, 255, 255, 0.50);
    }

    // option选项 上面的箭头
    ::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
        border-bottom-color: rgba(43, 45, 55, 0.80);
        z-index: 9999;
    }
    ::v-deep .popper__arrow {
        border: none;
    }
    // option选项 最外层
    ::v-deep .el-select-dropdown {
        border: none !important;
        background: rgba(43, 45, 55, 0.80) !important;
        z-index: 9999;
    }
    // option选项 文字样式
    ::v-deep .el-select-dropdown__item {
        color: rgba(255, 255, 255, 0.50) !important;
        z-index: 9999;
    }
    ::v-deep .el-select-dropdown__item.selected span{
        color: rgba(255, 255, 255, 0.80) !important;
        z-index: 9999;
    }
    // 移入option选项 样式调整
    ::v-deep .el-select-dropdown__item.hover{
        background-color: rgba(255, 255, 255, 0.06);
        color: rgba(255, 255, 255, 0.80) !important;
        z-index: 9999;
    }

    // 下拉框垂直滚动条宽度
    ::v-deep .el-scrollbar__bar.is-vertical {
        width: 10px;
        top: 2px;
    }
    // 下拉框最大高度
    ::v-deep .el-select-dropdown__wrap {
        max-height: 200px;
    }
}
</style>
