<template>
    <div ref="livePlayRef" style="position: relative;" :style="{height: height+'px'}">
        <dv-loading v-if="loading">
            <div class="loading-text">加载中...</div>
        </dv-loading>
        <div id="player">
            <div style="position: absolute;z-index: 9999;bottom:0;width: 100%">
                <div style="display: flex;justify-content: left;align-items: center;width: 100%">
                    <div class="grid"  :style="{width:playIconStyle.bgSize*3+'px',height:playIconStyle.bgSize*3+'px'}">
                        <div class="cell" v-for="(item,index) in  directionList" :key="index"
                             style="cursor:pointer;"
                             :style="{width:playIconStyle.bgSize+'px',height:playIconStyle.bgSize+'px'}">
                            <div :class="[`t-icon`,item.icon]" style="cursor:pointer;"
                                 :style="{color:'rgba(255,255,255,0.6)',fontSize: playIconStyle.fontSize+'px' }"
                                 @click="setControlMove(item.operation)"></div>
                        </div>
                    </div>
                    <div style="display: flex;margin-left: 20px;margin-bottom: -90px">
                        <div v-for="(item,index) in  operationList" :key="index" style="cursor:pointer;">
                            <div :style="{marginLeft:'15px',width:playIconStyle.bgSize+'px',height:playIconStyle.bgSize+'px'}"
                                 class="icon-bg"  >
                                <div v-if="item.icon" :class="[`t-icon`,item.icon]"
                                     style="font-weight: normal"
                                     :style="{color:'rgba(255,255,255,0.6)',fontSize: playIconStyle.fontSize+'px' }"
                                     @click="index<=1 ? setControlMove(item.operation):setOpt(item)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'ui-live-video',
    props: {
        height: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState({
            $token: state => state.$token,
            $farmInfo: state => state.$farmInfo,
            $farmDetailInfo: state => state.$farmDetailInfo,
            $farmUser: state => state.$farmUser,
        }),
    },
    data() {
        return {
            player: null,
            loading: false,
            directionList: [
                {
                    id: 1,
                    title: '左上',
                    operation: 4,
                },
                {
                    id: 2,
                    title: '上',
                    operation: 0,
                    icon: 't-icon-yuntaikongzhi-shang',
                },
                {
                    id: 3,
                    title: '右上',
                    operation: 6,
                },
                {
                    id: 4,
                    title: '左',
                    operation: 2,
                    icon: 't-icon-yuntaikongzhi-zuo',
                },
                {
                    id: 5,
                    title: '中间',
                    operation: 9,
                    icon: 't-icon-yuntaikongzhi',
                },
                {
                    id: 6,
                    title: '右',
                    operation: 3,
                    icon: 't-icon-yuntaikongzhi-you',
                },
                {
                    id: 7,
                    title: '左下',
                    operation: 5,
                },
                {
                    id: 8,
                    title: '下',
                    operation: 1,
                    icon: 't-icon-yuntaikongzhi-xia',
                },
                {
                    id: 9,
                    title: '右下',
                    operation: 7,
                },
            ],
            operationList: [{
                name: '缩小',
                operation: 9,
                icon: 't-icon-suoxiao1',
            },
                {
                    name: '放大',
                    operation: 8,
                    icon: 't-icon t-icon-fangda1',
                },
                {
                    name: '播放',
                    operation: 0,
                    icon: 't-icon-bofang',
                },
                {
                    name: '声音',
                    operation: 0,
                    icon: 't-icon-diyinliang',
                },
                {
                    name: '全屏',
                    operation: 0,
                    icon: 't-icon-quanping',
                },
            ],
            deviceId: '',
            playIconStyle: {
                fontSize: 0,bgSize: 0,
            },
        }
    },
    mounted() {

        // // 播放
        // player.play();
        // // 暂停
        // player.pause();
        // // 停止播放
        // player.stop();
        // // 销毁播放器
        // player.destroy();
        // // 进入全屏
        // player.setFullScreen();
        // // 退出全屏
        // player.exitFullScreen();
        // // 设置音量
        // player.setVolume(0.5);
        // // 设置多屏
        // player.setMultiScreen(4);
    },
    beforeDestroy() {
        if (this.player) {
            this.player.destroy()
            this.player = null
        }
        window.removeEventListener('resize', this.initPage);
    },
    methods: {
        init(deviceId) {
            this.$nextTick(() => {
                if (this.player) {
                    this.player.destroy()
                    this.player = null
                }
                this.initPage()
                this.player = new ImouPlayer('#player');
                this.loadPlay(deviceId)
                window.addEventListener('resize', this.initPage);
            })
        },
        initPage() {

            // this.height =Number (window.innerWidth / 19.2 * 5);

            //获取父容器的高度
            // this.height = this.$refs.livePlayRef.clientHeight;
            // console.log('this.height:', this.$refs.livePlayRef)
            this.playIconStyle.fontSize = Number(window.innerWidth / 19.2 * 0.3);
            this.playIconStyle.bgSize = Number(window.innerWidth / 19.2 * 0.5);

        },
        play(deviceId) {
            this.loading = true
            if (this.player && this.deviceId === deviceId) {
                // if(deviceId === this.deviceId) {
                setTimeout(() => {
                    this.player.play()
                    this.loading = false
                }, 1000)
                // }
                // else {
                //     this.deviceId = deviceId
                //     this.loadPlay(deviceId)
                // }
            } else {
                console.log('重新初始化播放器')
                this.deviceId = deviceId
                this.init(this.deviceId)
            }
        },
        stop() {
            console.log('停止播放')
            setTimeout(() => {
                if (this.player) {
                    this.operationList[2].icon = 't-icon-bofang'
                    this.player.pause()
                }
            }, 200)
        },
        setOpt(item) {
            if (this.player) {
                if (item.name === '播放') {

                    if (item.icon === 't-icon-bofang') {
                        item.icon = 't-icon-weibiaoti--'
                        this.player.play()

                    } else {
                        item.icon = 't-icon-bofang'
                        this.player.pause()
                    }
                }
                if (item.name === '全屏') {
                    if (item.icon === 't-icon-quanping') {
                        item.icon = 't-icon-suoxiao'
                        this.player.setFullScreen()
                    } else {
                        item.icon = 't-icon-quanping'
                        this.player.exitFullScreen()

                    }

                }
                if (item.name === '声音') {
                    if (item.icon === 't-icon-diyinliang') {
                        item.icon = 't-icon-jingyin'
                        this.player.setVolume(0)
                    } else {
                        item.icon = 't-icon-diyinliang'
                        this.player.setVolume(1)
                    }
                }
            }
        },
        loadPlay(deviceId) {
            this.$http.get('app/camera/getKitToken', {params: {deviceId: deviceId}}).then(res => {
                let ktToken = res.data.data
                // 播放器初始化
                this.player.setup({
                    src: [
                        {
                            url: `imou://open.lechange.com/${deviceId}/0/1?streamId=1`, // url拼接说明请见：2.2.3 轻应用播放地址说明
                            kitToken: ktToken, // 播放Token，通过接口getKitToken获取，具体请见：2.2.4 getKitToken接口协议说明
                        },
                    ], // 播放地址
                    // width: 760, // 播放器宽度
                    height: this.height, // 播放器高度
                    poster: '', // 封面图url
                    autoplay: true,
                    controls: false, // 是否展示控制栏
                });
                this.operationList[2].icon = 't-icon-weibiaoti--'
                setTimeout(() => {
                    this.loading = false
                }, 2000)
            })

        },
        setControlMove(operation) {
            if (this.$farmUser.type === 1) {
                this.$http.get('app/camera/controlMove', {
                    params: {
                        deviceId: this.deviceId,
                        channelId: 0,
                        operation: operation,
                        duration: 1000,
                    },
                })
                        .then(res => {
                            console.log('成功：', res)
                        }).catch(err => {
                    console.log('失败：', err)
                })
            } else {
                this.$message.error('当前非管理员模式，无法操作');
            }
        },
    },
}
</script>
<style lang="scss">
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    background-color: rgba(0, 0, 0, 0.35);
    border: rgba(210, 207, 207, 0.35) 2px solid;
    border-radius: 50%;
    cursor: pointer;
}

.icon-bg {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 50%;
    border: rgba(210, 207, 207, 0.35) 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cell {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
</style>
